<template>
  <div id="app">
    <Header />
    <router-view />
    <!--  网站底部  -->
    <footer>
      <Footer />
    </footer>

  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'App',
  components: {
    Header, Footer
  }
}
</script>

<style></style>
