export default {
  language: {
    zh: '中文简体',
    zt: '中文繁体',
    en: '英语',
    kokr: '韩文',
    '选择语言': '选择语言',
    '确定选择': '确定选择'
  },
  // 可以以页面为单位来写，比如首页的内容，写在index字段，个人中心写在center，共同部分写在common部分
  components: {
    desc: '众多组件覆盖开发过程的各个需求，组件功能丰富，多端兼容。让你快速集成，开箱即用'
  },
  js: {
    desc: '众多的贴心小工具，是你开发过程中召之即来的利器，让你飞镖在手，百步穿杨'
  },
  template: {
    desc: '收集众多的常用页面和布局，减少开发者的重复工作，让你专注逻辑，事半功倍'
  },
  nav: {
    components: '组件',
    js: '工具',
    template: '模板'
  },
  common: {
    intro: '',
    title: 'uView UI',
  },
  setting: {
  }
}