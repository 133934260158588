import Vue from 'vue'
import App from './App.vue'
import './assets/css/custom.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/base.css';

Vue.config.productionTip = false

import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import i18n from './utils/vue-i18n/index.js';

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


new Vue({
  i18n,
  router,
  //需要将store和vue实例进行关联，这里将其传递进去
  store,
  render: h => h(App),
}).$mount('#app')
