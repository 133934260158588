import en from './locales/en.js';
import zhHans from './locales/zh.js';
import zhHant from './locales/zt.js';
import kokr from './locales/kokr.js';
export const language = [{
  locale: "en",
  title: "English"
},
{
  locale: "zh-Hans",
  title: "中文简体"
},
{
  locale: "zh-Hant",
  title: "繁体中文"
},
{
  locale: "ko-kr",
  title: "韩文"
},
];
export default {
  'en': en,
  'zh-Hans': zhHans,
  'zh-Hant': zhHant,
  'ko-kr': kokr,
}