import Vue from 'vue'
import Router from 'vue-router'
// import store from '../store'
//获取原型对象上的push函数 解决重复点击路由报错（不设也没影响）
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

export const constantRouterMap = [
  {
    path: '/',
    component: () => import('@/views/index')
  },
  { path: '/products', component: () => import('@/views/products/index') },
  { path: '/news', component: () => import('@/views/news/index') },
  { path: '/detail', component: () => import('@/views/detail') },
  { path: '/about', component: () => import('@/views/about/index') },
  { path: '/contact', component: () => import('@/views/contact/index') },
  { path: '/404', component: () => import('@/views/404') },
  { path: '/500', component: () => import('@/views/500') },
  { path: '/502', component: () => import('@/views/502') },
  { path: '/*', component: () => import('@/views/404') }

]

const router = new Router({
  // mode: 'history',
  routes: constantRouterMap,
  // 刷新页面后，滚到顶部
  scrollBehavior (to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})

// router.beforeEach((to, from, next) => {
//   console.log("获取路径", to, from)
//   console.log("------------")
//   console.log(store.state.app.webNavbarList)
//   next()
// })

export default router