export default {
  language: {
    zt: '繁體中文',
    zh: '簡體中文',
    en: '英語',
    kokr: '韩文',
    '选择语言': '選擇語言',
    '确定选择': '確定選擇'
  },
  // 可以以页面为单位来写，比如首页的内容，写在index字段，个人中心写在center，共同部分写在common部分
  components: {
    desc: '眾多組件覆蓋開發過程的各個需求，組件功能豐富，多端兼容。讓你快速集成，開箱即用'
  },
  js: {
    desc: '眾多的貼心小工具，是你開發過程中召之即來的利器，讓你飛鏢在手，百步穿楊'
  },
  template: {
    desc: '收集众多的常用页面和布局，减少开发者的重复工作，让你专注逻辑，事半功倍'
  },
  nav: {
    components: '組件',
    js: '工具',
    template: '模板'
  },
  common: {
    intro: '',
    title: 'uView UI',
  },
  setting: {
    Home: '首頁',
    Product: '產品',
    Company: '公司',
    News: '新聞',
    My: '我的',
    Download: '下載',
    AMD: '芯 世 界',

    AAC: '開箱即用：適用於 Alveo™ 加速卡的 Vivado™ 軟體套件',
    FPGA: '硬體彈性與快速部署 — 經過實證的 FPGA 設計流程實現硬體自訂能力',
    LearnMore: '瞭解更多',
    EPYC: '隆重介紹 AMD EPYC™ 8004 處理器',
    CloudServices: '針對雲端服務、智慧邊緣和電信部署最佳化',
    KriaSOMs: '隆重介紹 AMD Kria™ K24 SOM：在邊緣加速創新',
    DSP: '利用可擴充的高功率效率 SOM 簡化 DSP 開發',
    Ultra: '適合超低延遲交易的閃電般加速器',
    purposeBuilt: '使用針對特定用途打造的 Alveo UL3524 FPGA 加速器，最大化您的交易績效並釋放潛力。',

    FeaturedProducts: '精選產品',
    ChangeHowYouGame: '打造顛覆性遊戲體驗',
    R7000: 'AMD 銳龍 7000 系列桌上型處理器',
    UnlockPeak: '銳龍和 Radeon 幫助你玩到極致',
    ourpartners: '合作夥伴精心打造了多種配置整機供您選購。',
    BreakthroughPerformance: '突破性能邊界',
    RX7000: 'AMD Radeon RX 7000 系列顯示卡',

    AboutCompany: '關於公司',
    Leadership: '以進取的領導力打造 AMD 與文化',
    AboutAMDCompany: '芯世界科技有限公司是一家專門從事研發、設計與銷售的Ai晶片科技公司。其核心技術產品廣泛用於人工智能、雲端運算、新能源和航空航天的圖形處理器、算力晶片組、Ai芯片等突發領域。',
    MoreDetails: '更多細節',
    LastNews: '最新消息',
    More: '更多',

    DownloadAPP: '下載 APP',
    theuniverse: '新觀察到的粒子運動可能會改變我們對宇宙的理解。',
    Android: '安卓',
    AppStore: '蘋果',

    ProductIntroduce: '產品介紹',
    Products: '產品',
    AI: '人工智慧',
    AIcloud: '將人工智慧從雲端推向邊緣再推向端點。',
    Automobile: '汽車行業',
    electrification: '推動具有自動駕駛、電氣化、網路功能和車載體驗的新一代汽車系統',
    MediaandEntertainment: '媒體及娛樂',
    filmmaking: '世界頂尖的工作室採用 AMD 技術來製作及呈現他們的創作。我們熱衷於製作電影。',
    Robotics: '機器人的自適應運算',
    ModularRobotics: '適用於多軸、視覺引導式和安全性智慧機器人的模組化平台',
    MoreProduct: '更多產品',

    AboutAMD: '關於芯世界',
    OurCompany: '我們公司',
    AMDintroduce: "芯世界的成立徹底解決了AMD在Ai和算力晶片領域的缺失，全方位提升了AMD的市場競爭力。 AMD芯世界致力於先進科技的開發和全面加速資料中心工作的推動，從通用計算到科技計算、雲端運算和量子計算，不斷更新反複運算，為科學家、工程師和設計師創造了良好的科研環境，也為優質產品的推出奠定了堅實的科技基礎。芯世界也代表了未來人工智能和算力發展的新世界，用戶可以通過廣泛，真實的分享彙聚更多的流量，參與享受芯世界帶來的無限市場紅利，一同走向未來科技的新世界。",
    OurVision: '我們的願景',
    ourlives: '高效能和自適應運算正在改變我們的生活。',
    Research: '研究',
    future: 'AMD Research 是領先級工業研究實驗室，許多改變未來的偉大構想和創新均誕生於此。',

    Innovation: '創新',
    technologies: 'AMD 在高效能和自適應運算技術上，不斷推動創新。',
    Quality: '品質',
    higheststandards: 'AMD 品質政策是我們承諾將產品推向最高標準的聲明。',
    JoinUs: '加入我們',
    impassioned: '這家製造世界上最先進處理器1的公司正在尋找世界上最有激情的人才。',
    FindOpportunities: '尋找機會',

    AboutUs: '關於AMD',
    ManagementTeam: '管理團隊',
    Careers: '職業機會',
    NewsEvents: '新聞與活動',
    Events: '活動',
    MediaLibrary: '媒體庫',
    Community: '社區',
    Support: '支援',
    Developer: '開發者',
    RedTeam: 'Red Team',
    Partners: '合作夥伴',
    PartnerResourceLibrary: '合作夥伴資源庫',
    footerInc1: "條款與條件 隱私權 商標 關於強迫勞動的聲明 公平與公開競爭 英國稅務策略 Cookies 政策 Cookies 設定",
    footerInc2: "超微半導體公司版權",

    attention: '每壹件事都值得妳關注',
    Newslist: '新聞列表',
  }
}