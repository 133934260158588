import VueI18n from 'vue-i18n';
import Vue from 'vue';
// import store from '@/store/index.js';
import message from './language.js';

Vue.use(VueI18n);

export default new VueI18n({
  messages: message,
  locale: localStorage.getItem('language') || 'en',
});