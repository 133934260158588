export default {
  language: {
    zh: 'Chinese',
    en: 'English',
    zt: 'Chinese Tranditional',
    kokr: '韩文',
    '选择语言': 'Select language',
    '确定选择': 'Confirm'
  },

  // 可以以页面为单位来写，比如首页的内容，写在index字段，个人中心写在center，共同部分写在common部分
  components: {
    desc: 'Numerous components cover the various requirements of the development process, and the components are rich in functions and compatible with multiple terminals. Let you integrate quickly, out of the box'
  },
  js: {
    desc: 'Numerous intimate gadgets are a weapon that you can call upon during the development process, allowing you to dart in your hand and pierce the Yang with a hundred steps'
  },
  template: {
    desc: 'Collection of many commonly used pages and layouts, reducing the repetitive work of developers, allowing you to focus on logic and get twice the result with half the effort'
  },
  nav: {
    components: 'Components',
    js: 'JS',
    template: 'Template'
  },
  common: {
    intro: 'UI framework for rapid development of multiple platforms',
    title: 'uView UI',
  },
  "setting": {
    Home: 'Home',
    Product: 'Product',
    Company: 'Company',
    News: 'News',
    My: 'My',
    Download: 'Download',
    AMD: 'Core World',

    AAC: 'Out-of-the-Box Ready: Vivado™ for Alveo™ Accelerator Cards',
    FPGA: 'Hardware flexibility and fast deployment—proven FPGA design flows for hardware customization',
    LearnMore: 'Learn More',
    EPYC: 'Introducing AMD EPYC™ 8004 Processors',
    CloudServices: 'Optimized for Cloud Services, Intelligent Edge, and Telco Deployments',
    KriaSOMs: 'Introducing AMD Kria™ K24 SOMs: Accelerating Innovation at the Edge',
    DSP: 'Simplify DSP development with a scalable, power-efficient SOM',
    Ultra: 'Lightning-Fast Accelerator for Ultra-Low Latency Trading',
    purposeBuilt: 'Maximize your trades and unlock potential with the purpose-built Alveo UL3524 FPGA accelerator.',

    FeaturedProducts: 'Featured Products',
    ChangeHowYouGame: 'Change How You Game',
    R7000: 'AMD Ryzen™ 7000 Series Desktop Processors',
    UnlockPeak: 'Unlock Peak Performance Only With AMD',
    ourpartners: 'Browse preconfigured systems from our partners.',
    BreakthroughPerformance: 'Breakthrough Performance',
    RX7000: 'AMD Radeon™ RX 7000 Series graphics cards',

    AboutCompany: 'About Company',
    Leadership: 'Building AMD and the Culture with Progressive Leadership',
    AboutAMDCompany: 'AMD is an AI chip technology company specializing in R&D, design and sales. Its core technology products are widely used in many fields such as graphics processors, computing chipsets, and AI chips for artificial intelligence, cloud computing, new energy, and aerospace.',
    MoreDetails: 'More Details',
    LastNews: 'Last News',
    More: 'More',

    DownloadAPP: 'Download APP',
    theuniverse: 'Newly observed particle movements could change our understanding of the universe.',
    Android: 'Android',
    AppStore: 'iPhone ',

    ProductIntroduce: 'Product Introduce',
    Products: 'Products',
    AI: 'AI',
    AIcloud: 'Advancing AI from cloud to edge to endpoints',
    Automobile: 'Automobile',
    electrification: 'owering next-generation automotive systems for automated driving, electrification, networking, and in-vehicle experience',
    MediaandEntertainment: 'Media and Entertainment',
    filmmaking: "The world's top studios use AMD technology to produce and deliver their creations. We are passionate about filmmaking.",
    Robotics: 'Adaptive Computing in Robotics',
    ModularRobotics: 'Modular platforms for multi-axis, vision-guided, and safety-enabled intelligent robotics',
    MoreProduct: 'More Products',

    AboutAMD: 'About AMD',
    OurCompany: 'Our Company',
    AMDintroduce: "AMD has completely solved AMD's shortcomings in the field of AI and computing chips, and comprehensively improved AMD's market competitiveness. AMD Core World is committed to the development of advanced technology and the promotion of comprehensive acceleration of data center work. From general computing to scientific computing, cloud computing and quantum computing, it constantly updates and repeats calculations, creating a good scientific research environment for scientists, engineers and designers. It also laid a solid scientific and technological foundation for the launch of high-quality products. Core World also represents a new world for the development of artificial intelligence and computing power in the future. Users can gather more traffic through extensive and real sharing, participate in and enjoy the unlimited market dividends brought by Core World, and move toward a new world of future technology together.",
    OurVision: 'Our Vision',
    ourlives: 'High performance and adaptive computing is transforming our lives.',
    Research: 'Research',
    future: 'AMD Research is the leading industry research lab where great ideas and innovation are transforming the future.',

    Innovation: 'Innovation',
    technologies: 'AMD is driving innovation in high performance and adaptive computing technologies.',
    Quality: 'Quality',
    higheststandards: 'The AMD Quality Policy is a declaration of our commitment to uphold our products to the highest standards.',
    JoinUs: 'Join Us',
    impassioned: 'The company making the world’s most advanced processors1 seeks the world’s most impassioned people.',
    FindOpportunities: 'Find Opportunities',

    AboutUs: 'About Us',
    ManagementTeam: 'Management Team',
    Careers: 'Careers',
    NewsEvents: 'News & Events',
    Events: 'Events',
    MediaLibrary: 'Media Library',
    Community: 'Community',
    Support: 'Support',
    Developer: 'Developer',
    RedTeam: 'Red Team',
    Partners: 'Partners',
    PartnerResourceLibrary: 'Partner Resource Library',
    footerInc1: "Terms and Conditions Privacy Trademarks Statement on Forced Labor Fair & Open Competition UK Tax Strategy Cookies Policy Cookies Settings",
    footerInc2: "Advanced Micro Devices, Inc",
    // NewsEvents
    attention: 'Everything deserves your attention',
    Newslist: 'News List',
  }
}