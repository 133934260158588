<template>
  <footer class="footer--  ">
      <div class="container">
        <div class="content___o main_width  ">
         <div class="mb-3 footer-img">
          <div class="footer_logo">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIcAAAAhCAYAAADzorj5AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAS7SURBVHgB7Zv/VdUwFMe/j+P/6gZhAtnAOoE4gXUCZALKBOIElgnECegG4gSUCcAJ4r001ccjufnRNC0ePufkPHhN0zT99t6bm7wNnlktWuuKPhQWYoNnVguJQ9HHJRYSSLQ4jJrdDW42HRIxg6Esh3pqt8dEqP0D+ngV0j7V5XoHiKenckft3SEDSwskGOporf1USITObRxt3ppBmtL3Q6HPNdLuVYL7/N20ozABPp/KtS7MHuL4GFDnBPnht/gydZDNed9QFu7zobnuNfXhW2r/jVV7h8EqFSNYHObGqoCqlR7Md24UFX4TX8WcpP+Z5ajzZqDGIJIvSGAJgcRYjiaibo15YNEFD64R0tr89WfjIhQiKS2QGHG8jaj7MfYNj4B9eKjr+o55hMHBZm8poSgMbjJ6jEoKJEgcegjYFMLhm/6M+eDAVWzfmO8K8/CVHtK+pfDsb5/KJyqdpw2FQbzRlBLIi8B6UiDawy6cI3pAZ7mmdBa+UPs8Bb3YPWAsy5zidGIeXMuF+sEBKYtUOapzfFbTOS0SrkPnskAqpHGCqVZVD9MoFxfaPf3Uvrfbci2pLRu3u8Ev/X+k46ktfZGmsg0C0f5p6DUWgK57qT2EuJVGONZSOcPgg228x7yw++IZjOJ/9CCUM6wIY0k+CVVYPBUSoPNCUgvJhIjDFYjem3TjNs4ddSo9ISkWiMIQ3LEwknz43JiscSdUiX7IenCdDWZkz9OBGm6/1G39fQE3uZJinXBMUfkJ2Yd2WJZz4ViFCEoIg/FZDknRp+Mfnjcjl/U4375mJMdUbrAsnXCMXUvQtLaUMJg9oRMKbkV3loWwH3CTxTfSNRvIb6CNUzpv8TgkYOHQK46SwmAky9EIx2wPqIU7MD3UmZJiNMg1fVwFVv9qBPUUUNLB0sJgJHFIGdFu9wtPYJo7KRaSAPpFfVok15GbJYTBWMXhCURbwURKgelRRuvBQmSBuCxVj2FFdDUE3HvvOG8RYTAuyyHFCE6f7wlMeXBqZGIrhbzL/fc5NgdlppIO2vq7pDCYR+lz7V+aP9HywpcSjrHosgWHNKBX1BdOMI17NdiSfFihMBjJknWO7xssiG1tpYFMhXQOeFo7ZSvhLrwuYQTNgj1mwWBlmP5J1vgXVsgDt2L84twp7+w7xXhGshlosU4uPMdXlfIf2bUcbPrm3jFV5bYea8W8bOzy3gjV2iluMCHBeGUC+t++irviOEIZ2MR2+E8xoqgxjKcSqvJDSs36wrR9GVH/3OSJGJ7mv4HQv7/iMAtXrr2fvNX+NSIwinZ1nJfDj2fc6zE3by3L9iyIlxjGUCHMAp8WDJ63hbG9H8S5jXLbckgJI5/PfAS7Dbp4B3cAy9dr8DSpMH2XWcm0/gNhjPgEch+QBgSisesZI9J6S7ak2BNjnG43KINVGCPSlsNxtiIFov2E4LGFO4uZNSn2BBjji33b1saZEIUx4hLIKA5pepkcMHnWW5hSAfBS8P13GLYM7Jspd6k4K0gYIzaBvDAJGukBdphGC7f1uHdpWwPWwc3U5Ba/rX1E2/xdyotxZ8rVggm5KGGMhASpz6wEnUaLiWizKTr6V/bPlIMeUOzve29yBboskD8GVTtql+2VAAAAAABJRU5ErkJggg==">
            <span class="ml-2">{{ $t('setting.AMD') }}</span>
          </div>
            <div class="contact_media">
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEvSURBVHgB5VXtbYNADPWhDsAGZYNkBEZgBDpBRmg6QbsB6QbtBO0G0AloJ2g3cJ9lE11Q4EySP1Ge9ITBX3f2+SC6dgQ6Ecxc4lGBK/APfA8h7CJ9HhtvwIKcgO0zH0cLVuCjyLGDKHpPEnNO4Rdc7+NFin4uiWzbnFNoza4aHMdOzbFE+FayH3Xs+DFh1LA2c2mCZryygtNbb42LEmSspZDtvNA81kYPfgbhjvQMb8CcLoduEDIMhyR4pcuiO3hjPX7e+qbQTqaFcss6C+egngqeW3DPIE2hH8fNBiHqxTnNfkpaWJlOwZa8YB28ekG5dkuC5xa8dwafHdDAeuNJ3QvwnvQn4umD9OwBvXtLWtqKvXMgZZM+uQ5DGCUq8ChJ75xVpPoGv8AOK/6km8I/c8tXR/4EsjkAAAAASUVORK5CYII=">
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADYSURBVHgB7ZOBEYIwDEVTzwFwA0ZwAxnFDcQNcCOdwBF0A9yAbhCTE2oo0iuV3Okd7y60haY/NAkg4oGsQR1Kw0/Qw2oLwBq+w5KdxTojK9rxBaZTflKk97XclCpQwQi+QEoOrDFmExKgIe/WK5jO3TswJ7u5KxGHpwr47Mm2Yx/nEAiiLhCb5AuZK0tK8qObkzvXfCb21tIxttEaeWgvQmO42awQ6zH3FQ2SHfsHGUVXtHPuA1eqXKbwLs3dwBOnc/X8q9Dm/y/TReA3BCwowgIn0IEDPz4Bet+ERwdvfhQAAAAASUVORK5CYII=">
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADgSURBVHgB7ZTtEYIwDIZTz//iBHYER3AUNnAlNoENYANwAnGCmJwp5Dy1KVdPf/DcvdcCTT/e0ACs/BoXOohYUFOSPGknrZfPheiZgTQqXUgdqXHODaAm96Qe89HznHqBGvNTTxbxE+RnJJv2m4SABh6eW5lzZjmvjONcVWgkeQE1vkTDT7F4AXWaNhaTkoPPPr9h0QK0uTM1LcwXMRpgskhsqdEIx2zBCPstO47aogkX7ZoaaJqcCDnoID/N1MMvFjtdrvnFiXQkHWAu0Sz/YoehRDOD6CZtRe6MsPIX3AFYLWPrqtOQggAAAABJRU5ErkJggg==">
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGySURBVHgBrVaLcYMwDDVM4A3qEegGdIJ2g3aD0AmaDUomgG6QTkA7QbOB2SBsoEpBughi41zIu9M5NvaT9ORPMqMAABabDVqJ5tAs2xIGth7tG22fZVl/MQvJN2hHuA8+hDcTcmxqHiPvO24HNRaCzrDg7B333zGTmsid8tyyTDeDOYDVsDRQ8YA3N4Ll/UN75b5nzi11Ou5UVxBRti4wDjpIIuZ+l5uzZocF0ppSxi4RSHSNcrbj9mvG5Qycd04ZIC9geWfRtyKwrpTvOr0yELmX1HmRVQReOXGBwE7QDorZpFaRu0CUFsbCnrQOBHfhYB6FSPNmItBSgNreSQcwPRt2wYFNrc/NCuBJHVQ3GEgemsCXlSwuTAR6Y+Caw9UOGLKnG4gUmb5xtzULUQjK2bhThfZUbNbcQnqbToqcOmge4kgdNE8SDRGJRNcnlksXlH7v0R5j2mtvHXurTQIQuewC8+SG7vTNNzksa6BkreSwSB0aWP/gNKpGTp5Megs+eU5vxsf7x0x1X4JDe0AjHglwfDKV5y3cB0dQj1c2S48ieUF75qicSUP/bflFq/UV8g+6Kj+ZC7Q/qgAAAABJRU5ErkJggg==">
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADrSURBVHgB1ZXREYIwDIZTz3cdgQ10AxmlI7ABI9AR2MARPDfQCWQD3CAmErheCQpceeh3l7YHzf8DzQWA1DE8IGJB0wnicjfG1Cxe4XZUhkdxbCRicKQ4U7zBc7MQCdbqRXewMckaHIZVcOo3ihxWwrmiMQAT5fVacuiasGZwFeFZRnT9SFEoOa1vNipT7EpMMyopMhEuRSjcU8h9O2ngPaFF/bVDYd6TK7m/DYLvWytGI+FVBl5SJkaO13/2DgZ7mAl1xoYmCwtJv1Vwu26ha68P4PYah0zieyAOt8P1v0xL0wXi8qTCcJA8H465gyTHQrYwAAAAAElFTkSuQmCC">
            </div>
        </div >
          <div class="guide_list ">
              <div class="footer-text">
                <h6>{{ $t('setting.Product') }}</h6>
                <p @click="golink(1)">{{ $t('setting.AboutUs') }}</p>
                <p @click="golink(2)">{{ $t('setting.ManagementTeam') }}</p>
                <p @click="golink(3)">{{ $t('setting.Careers') }}</p></div>
               <div class="footer-text">
                  <h6>{{ $t('setting.NewsEvents') }}</h6>
                  <p @click="golink(4)">{{ $t('setting.News') }}</p>
                  <p @click="golink(5)">{{ $t('setting.Events') }}</p>
                  <p @click="golink(6)">{{ $t('setting.MediaLibrary') }}</p>
                </div>
              <div class="footer-text">
                <h6>{{ $t('setting.Community') }}</h6>
                <p @click="golink(7)">{{ $t('setting.Support') }}</p>
                <p @click="golink(8)">{{ $t('setting.Developer') }}</p>
                <p @click="golink(9)">{{ $t('setting.RedTeam') }}</p>
              </div>
                <div class="footer-text">
                  <h6>{{ $t('setting.Partners') }}</h6>
                  <p @click="golink(10)">{{ $t('setting.AboutAMD') }}</p>
                  <p @click="golink(11)">{{ $t('setting.PartnerResourceLibrary') }}</p></div>
                </div>
              </div> 
      </div>

      <div class="footer-botoom">
          <p>{{ $t('setting.footerInc1') }}</p>
          <p>© 2023{{ $t('setting.footerInc2') }}</p>
      </div>
      
          
  </footer>
</template>
<script>
export default {
  name: 'Footer',
  data() {
    return {

    }
  },
  methods: {
    golink(type){
      if(type==1){ window.open('https://www.amd.com/en/corporate.html')}
      if(type==2){ window.open('https://www.amd.com/en/corporate/leadership.html')}
      if(type==3){ window.open('https://careers.amd.com/careers-home')}
      if(type==4){ this.$router.push({ path: '/news' });}
      if(type==5){ this.$router.push({ path: '/news' });}
      if(type==6){ window.open('https://www.amd.com/en/legal/terms-and-conditions/media-library.html')}
      if(type==7){ window.open('https://community.amd.com/t5/developers/ct-p/devgurus')}
      if(type==8){ window.open('https://community.amd.com/t5/developers/ct-p/devgurus')}
      if(type==9){ window.open('https://www.amd.com/en/partner.html')}
      if(type==10){ window.open('https://www.amd.com/en/partner/resources/resource-library.html')}

    },
  }
}

</script>
<style scoped>
.footer-- {
  /* padding: 20px 0 10px; */
  background-color: #000;
  padding: 46px 0 30px;
  color: #fff;
  /* border-top: 1px solid #ebebeb;
  text-align: center;
  background-color: #fafafa; */
}
.main_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.content___o {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 5px;
}

.footer-- .content___o .guide_list {
  margin-left: 9%;
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
}


.footer-- .content___o .guide_list .footer-text {
   min-width: 150px;
   margin-bottom: 10px;
}

.footer-- .content___o .guide_list h6{
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
}

.footer-- .content___o .guide_list p{
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  opacity: .8;
  margin-bottom: 1em;
}


.footer-- .content___o .footer_logo {
  display: flex;
    align-items: flex-end;
    margin-bottom: 24px;
}

.footer-- .content___o .contact_media {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-- .footer-botoom {
  border-top: 1px solid #ebebeb;
  padding-top: 20px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  color: #fff;
  font-size: 11px;
  font-weight: 400;
  opacity: .6;
}

@media screen and (max-width:750px){
  .footer-- {
    padding: 25px 0 30px;
  }
  .footer-img{
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
  }
  .footer-img  .footer_logo {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

</style>
