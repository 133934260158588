<template>
  <header class="header--  shadow-sm_ position-fixed">
    <b-navbar toggleable="md" type="dark" variant="dark " style="background-color: #fff;">
      <div class="container">
        <b-navbar-brand href="/">
          <div class="flex flex-column align-center ">
            <img src="./../static/logo.png" class="d-inline-block align-top "
            style="width: 120px;height: 27px;" alt="Kitten">
            <span class="mt-2" style="font-size:12px">{{ $t('setting.AMD') }}</span>
          </div>
          
          </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse">
          <template #default="{ expanded }">
            <img  v-if="!expanded" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA0SURBVHgB7dSxDQAgFAJR4v47Mgq2lhqbn3BvAKoLErokcf753FxCl8eKfLNJRW3CF2GkDdp4vocb1mtrAAAAAElFTkSuQmCC" class="icon_bar___fSLGE">
            <img v-else src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABlSURBVHgB7ZRBDgAQDAQbL+n/P+Up60LigqUaCTZxa2eEpiI/bwZAzEeJWi31wiY3YCSp4JgV6EjC1CxLzPAeaBu8I9kHb0im4EFOx/WJXD/ZdUwZgEnCNsKwKiJ7K6wsu5+7kgDAuo8Az/0thgAAAABJRU5ErkJggg==">
          </template>
        </b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item v-for="(item, index) in navList" :key="index" @click="navClick(item)"><a href="#"
                :class="{ 'isActive': isActive == item.title }">{{
                  item.title
                }}</a></b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item @click="go(1)"><a href="#" 
                >{{ $t('setting.My') }}</a></b-nav-item>
                <b-nav-item @click="go(2)"><a href="#" 
                >{{ $t('setting.Download') }}</a></b-nav-item>

            <div  @mouseenter="showDropdown(2)" @mouseleave="hideDropdown(2)" >
            <b-nav-item-dropdown right ref="dropdown2">
                <template #button-content>
                  <span>{{ language }}</span>
                </template>
                <b-dropdown-item href="#" @click="changeLanguage('en')">English</b-dropdown-item>
                <b-dropdown-item href="#" @click="changeLanguage('zh-Hant')">繁体中文</b-dropdown-item>
                <b-dropdown-item href="#" @click="changeLanguage('ko-kr')">한국어</b-dropdown-item>
              </b-nav-item-dropdown>
            </div>

          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
  </header>
</template>
<script>
export default {
  data () {
    return {
      keyword: "",
      isActive: '',
      language: '',
      navList: [
        {
          title: this.$t('setting.Home'),
          url: '/',
        },
        {
          title: this.$t('setting.Product'),
          url: '/products',
        },
        {
          title: this.$t('setting.News'),
          url: '/news',
        },
        {
          title: this.$t('setting.Company'),
          url: '/about',
        },
        // {
        //   title: '联系我们',
        //   url: '/contact',
        // }
      ],
    };
  },
  created () {

  },
  mounted () {
    // this.language = localstorage.getItem('language') || 'en'
    let lang =  localStorage.getItem('language') || 'en'
    if(lang === 'en') this.language = 'English'
    if(lang === 'zh-Hant') this.language = '繁体中文'
    if(lang === 'ko-kr') this.language = '한국어'
    this.isActive = sessionStorage.getItem('url_name') || '首页'
  },
  methods: {
    showDropdown(type) {
      if(type === 1) {
        this.$refs.dropdown.visible = true;
      } else {
        this.$refs.dropdown2.visible = true;
      }
     
    },
    hideDropdown(type) {
      if(type === 1) {
        this.$refs.dropdown.visible = false;
      } else {
        this.$refs.dropdown2.visible = false;
      }
    },
    onSearch (e) {
      e.preventDefault();
      this.$router.push({ path: '/products', query: { keyword: this.keyword } });
    },
    navClick (item) {
      sessionStorage.setItem('url_name', item.title)
      this.isActive = item.title
      this.$router.push({ path: item.url });
    },
    changeLanguage(type){
      this.$store.commit('CHANGE_LANGUAGE', type)
      this.$i18n.locale = type
      if(type === 'en') this.language = 'English'
      if(type === 'zh-Hant') this.language = '繁体中文'
      if(type === 'ko-kr') this.language = '한국어'
      this.navList = [
        {
          title: this.$t('setting.Home'),
          url: '/',
        },
        {
          title: this.$t('setting.Product'),
          url: '/products',
        },
        {
          title: this.$t('setting.News'),
          url: '/news',
        },
        {
          title: this.$t('setting.Company'),
          url: '/about',
        },
      ]
    },
    go (type) {
      if(type == 1){
        window.open('https://account.amd.com/zh-tw/profile.html')
      } else {
         console.log('this.$route.path',this.$route.path)
         if(this.$route.path !== '/') {
           this.$router.push({ path: '/' })
         } else {
          const element = document.querySelector('#down-app');
          element.scrollIntoView({ behavior: 'smooth' });
         }
      }
    
    }
  }
};
</script>

<style scoped>
/deep/ .b-nav-dropdown {
  width: 100px;
}
/* .bg-info {} */
.header-- .bg-dark {
  background-color: #14192b !important;
}
#nav-collapse .nav-link a:link{
  font-size: 1.2rem;
  /* color: #fff; */
}

#nav-collapse .ml-auto span{
  font-size: 1.2rem;
  color: #fff;
}
#nav-collapse /deep/ .dropdown-menu {
  margin-top: -0.1px;
  background-color: #2b2e41;
}

#nav-collapse /deep/ .dropdown-item:hover,#nav-collapse /deep/ .dropdown-item:focus {
  color: #41b291;
  background-color: #2b2e41;
}
dropdown-menu
#nav-collapse .dropdown-menu  .a:link {
  font-size: 1.2rem;
  /* color: #333!important; */
}


#nav-collapse .dropdown-menu  li{
  height: 40px;
}
.search-box {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

}

.search-box .nav-input {
  flex: 1;
}

#nav-collapse .isActive {
  /* color: #dc3545; */
  color: #41b291!important;
}


#nav-collapse /deep/ button {
  box-shadow: 0 !important;
  border: 0px  !important;
}


#nav-collapse.show {
  text-align: right;
  margin-right: 40px;
}
#nav-collapse.show .nav-item {
  height: 50px;
}
/* #nav-collapse /deep/ .navbar-dark, .navbar[data-bs-theme=dark]{
  border: 0px;
} */
@media screen and (max-width:750px){
  #nav-collapse {
    text-align: right;
    margin-right: 40px;
  }
  /deep/ .b-nav-dropdown {
  width: auto;
}
}
</style>
