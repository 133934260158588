export default {
  language: {
    zh: '中文简体',
    zt: '中文繁体',
    en: '英语',
    kokr: '韩文',
    '选择语言': '选择语言',
    '确定选择': '确定选择'
  },
  // 可以以页面为单位来写，比如首页的内容，写在index字段，个人中心写在center，共同部分写在common部分
  components: {
    desc: '众多组件覆盖开发过程的各个需求，组件功能丰富，多端兼容。让你快速集成，开箱即用'
  },
  js: {
    desc: '众多的贴心小工具，是你开发过程中召之即来的利器，让你飞镖在手，百步穿杨'
  },
  template: {
    desc: '收集众多的常用页面和布局，减少开发者的重复工作，让你专注逻辑，事半功倍'
  },
  nav: {
    components: '组件',
    js: '工具',
    template: '模板'
  },
  common: {
    intro: '',
    title: 'uView UI',
  },
  setting: {
    Home: '첫 장',
    Product: '產제품品',
    Company: '회사',
    News: '메시지',
    My: '내 거',
    Download: '다운로드',
    AMD: '핵심 세계',

    AAC: '구매 즉시 사용 가능한 Alveo™ 가속기 카드용 Vivado™',
    FPGA: '하드웨어 유연성 및 빠른 배포 - 하드웨어 맞춤 구성을 위한 검증된 FPGA 설계 흐름',
    LearnMore: '자세히 알아보기',
    EPYC: 'AMD EPYC™ 8004 프로세서 소개',
    CloudServices: '클라우드 서비스, 지능형 에지 및 텔코 배포에 최적화',
    KriaSOMs: 'AMD Kria™ K24 SOM 출시: 에지에서 혁신 가속화',
    DSP: '확장 가능하고 전력 효율적인 SOM으로 DSP 개발 단순화',
    Ultra: '아주 낮은 지연율의 거래를 위한 번개처럼 빠른 가속기',
    purposeBuilt: '특수 제작된 Alveo UL3524 FPGA 가속기로 거래를 극대화하고 잠재력을 발휘하세요.',

    FeaturedProducts: '주요 제품',
    ChangeHowYouGame: '파괴적인 게임 경험을 창조하세요',
    R7000: 'AMD Ryzen 7000 시리즈 데스크탑 프로세서',
    UnlockPeak: 'Ryzen과 Radeon은 극한의 플레이를 도와줍니다.',
    ourpartners: '우리 파트너는 귀하가 선택할 수 있도록 다양한 구성을 갖춘 완전한 기계를 신중하게 만들었습니다.',
    BreakthroughPerformance: '성능 한계를 뛰어넘다',
    RX7000: 'AMD Radeon RX 7000 시리즈 그래픽 카드',

    AboutCompany: 'AMD 소개',
    Leadership: '공격적인 리더십으로 AMD와 문화 구축',
    AboutAMDCompany: 'AMD 회사는 R&D, 설계, 판매를 전문으로 하는 AI 칩 기술 기업입니다. 핵심 기술 제품은 그래픽 프로세서, 컴퓨팅 칩셋, 인공지능용 AI 칩, 클라우드 컴퓨팅, 신에너지, 항공우주 등 신흥 분야에서 널리 사용됩니다.',
    MoreDetails: '자세한 내용은',
    LastNews: '최근 뉴스',
    More: '더',

    DownloadAPP: '다운로드 APP',
    theuniverse: '새로 관찰된 입자 운동은 우주에 대한 우리의 이해를 바꿀 수 있습니다.',
    Android: '안드로이드',
    AppStore: '사과',


    ProductIntroduce: '제품 설명',
    Products: '제품',
    AI: '인공지능',
    AIcloud: 'AI를 클라우드에서 에지, 엔드포인트로 확장',
    Automobile: '자동차',
    electrification: '자율 주행, 전기화, 네트워킹, 차량 내 경험을 위한 차세대 자동차 시스템을 구동합니다.',
    MediaandEntertainment: '미디어 및 엔터테인먼트',
    filmmaking: "세계 최고의 스튜디오에서는 AMD 기술을 사용하여 작품들을 제작 및 제공합니다. 우리는 영화 제작에 대한 열정을 가지고 있습니다.",
    Robotics: '로봇 공학의 적응형 컴퓨팅',
    ModularRobotics: '멀티 엑시스, 비전 가이디드, 안전 지원 지능형 로봇 공학을 위한 모듈식 플랫폼을 사용합니다.',
    MoreProduct: '더 많은 제품',

    AboutAMD: 'AMD 소개',
    OurCompany: '회사',
    AMDintroduce: "AMD 이번 설립은 AI 및 컴퓨팅 파워 칩 분야에서 AMD의 단점을 완전히 해결하고 AMD의 시장 경쟁력을 종합적으로 향상시켰습니다. AMD Core World는 첨단 기술 개발과 데이터 센터 작업의 포괄적인 가속화 촉진에 전념하고 있으며, 일반 컴퓨팅부터 과학 컴퓨팅, 클라우드 컴퓨팅, 양자 컴퓨팅에 이르기까지 끊임없이 계산을 업데이트하고 반복하여 과학자들을 위한 좋은 과학 연구 환경을 조성합니다. , 엔지니어, 디자이너 등 고품질 제품 출시를 위한 탄탄한 과학기술 기반을 마련했습니다. 코어월드는 미래의 인공지능과 컴퓨팅 파워의 발전을 위한 새로운 세상을 의미하며, 사용자는 광범위하고 실질적인 공유를 통해 더 많은 트래픽을 모으고, 코어월드가 제공하는 무제한적인 시장 배당에 참여하고 향유하며 새로운 세계를 향해 나아갈 수 있습니다. 미래기술을 함께합니다.",
    OurVision: '우리의 비전',
    ourlives: '고성능 및 적응형 컴퓨팅은 우리의 삶을 변화시키고 있습니다.',
    Research: '연구',
    future: 'AMD Research는 훌륭한 아이디어와 혁신으로 미래에 변화를 가져오는 업계 선두의 연구실입니다.',
    Innovation: '혁신',
    technologies: 'AMD는 고성능 및 적응형 컴퓨팅 기술의 혁신을 주도하고 있습니다.',
    Quality: '품질',
    higheststandards: 'AMD 품질 정책은 우리 제품을 최고 수준으로 유지하겠다는 약속입니다.',
    JoinUs: '참여하기',
    impassioned: '세계에서 가장 진보된 프로세서1를 만드는 회사가 세계에서 가장 열정적인 사람들을 찾고 있습니다',
    FindOpportunities: '학생으로서의 기회 찾기',

    AboutUs: 'AMD 소개',
    ManagementTeam: '관리팀',
    Careers: '채용',
    NewsEvents: '뉴스 및 이벤트',
    Events: '이벤트',
    MediaLibrary: '미디어 라이브러리',
    Community: '커뮤니티',
    Support: '지원',
    Developer: '개발자',
    RedTeam: '레드 팀',
    Partners: '파트너',
    PartnerResourceLibrary: '파트너 리소스 라이브러리',

    footerInc1: "이용 약관 개인 정보 보호 상표 강제 노동에 관한 성명 공정하고 공개적인 경쟁 영국 조세 전략 쿠키 정책 쿠키 설정",
    footerInc2: "고급 마이크로 디바이스, 저작권",

    attention: '모든 것이 당신의 관심을 끌 가치가 있습니다',
    Newslist: '뉴스 목록',

  }
}